import React from "react"
import format from "date-fns/format"
import { Typography } from "@mui/material"

const DateLine = props => {
  return (
    <Typography sx={{ fontStyle: "italic" }} variant="content">
      {format(new Date(props.date), props.format)}
    </Typography>
  )
}

export default DateLine
