import { Typography } from "@mui/material"
import { styled } from "@mui/system"

const CardTitle = styled(Typography)(() => ({
  fontSize: "1.25rem",
  fontWeight: "bold",
  fontStyle: "normal",
  align: "left",
}))

export default CardTitle
